import { useState, useEffect, useRef } from 'react'
import BaseLayout from "../suporte/restrito/layout/BaseLayout";
import icones from '../../assets/icons/icones';

import { Show } from '../../components/Show/Show';
import api from '../../api/api';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import { formataData } from '../../utils/formatadores';

import Programa from '../suporte/restrito/components/programa/programa';
import UploadComponent from '../../components/UploadComponent';
import toast from "react-hot-toast";

import { Input } from '../../components/Input';
import { verificaEquipe } from '../../utils/validadores';


import './styles.css'


export default function Clientes() {
    const buscaInputRef = useRef(null);
    
   const [busca, setBusca] = useState('')
   const [clientes, setClientes] = useState([])
   const [expandedBlocks, setExpandedBlocks] = useState([]);

   const [data, setData] = useState('')

   const [chave, setChave] = useState("C")
   const [codigo, setCodigo] = useState("")
   const [contato, setContato] = useState("")
   const [descricao, setDescricao] = useState("")
   const [login, setLogin] = useState(localStorage.getItem("suporte_usuario"))
   const [showOptions, setShowOptions] = useState(false)
   const [tipoProblema, setTipoProblema] = useState('')
   const [tipoSuporte, setTipoSuporte] = useState('')

   const [progressBar, setProgressBar] = useState({ started: false, pc: 0 });
   const [imageDetails, setImagesDetails] = useState([])

   const [imagens, setImagens] = useState([]);
   const [resetImages, setResetImages] = useState(false);

   const [enableEdit, setEnableEdit] = useState(true)

   const [loading, setLoading] = useState(false)
   const [uploadMessage, setUploadMessage] = useState(null)

    
   const [error, setError] = useState({
        error: false,
        message: ''
    })

   const handleProgramaChange = (value) => {
        setTipoProblema(value);
    };

   const handlePesquisaCliente = async ()  => {
       try {
        if(busca.length < 3){
            setError({
                error: true,
                message: "Informe ao menos três letras."
            })
            setLoading(false)

            setTimeout(() => {
                setError({
                    error: false,
                    message: ''
                });
            }, 3000);
            
            return;
        }

        setLoading(true)
        
        const response = await api.get(`/consulta/cliente/${busca}|${localStorage.getItem("suporte_usuario")}`)
            setClientes(response.data.dados)         

        setError({
            error: false,
            message: ""
        })
        
        setLoading(false)
        return;
       } catch (error) {
        setError({
            error: true,
            message: "Nenhum cliente encontrado na busca."
        })
        setLoading(false)

        setTimeout(() => {
            setError({
                error: false,
                message: ''
            });
        }, 3000);
        setClientes([])
        setLoading(false)
        setBusca('')
    }
    }

   const toggleExpanded = (index) => {
        const newExpandedBlocks = [...expandedBlocks];
        newExpandedBlocks[index] = !newExpandedBlocks[index];
        setExpandedBlocks(newExpandedBlocks);
    };
    
    useEffect(() => {
        function getCurrentDate (){
          const date = new Date();
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
          setData(getCurrentDate())          
    }, [])  

    
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handlePesquisaCliente();
        }
    };

    const handleRegister = (e) => {
        e.preventDefault()

        if(!data || !chave || !codigo || !login || !descricao || !contato || !tipoProblema){
            toast.error("Todos os campos são obrigatórios.");
            return;
        }

        const form = new FormData();
        
        form.append('data_chama', formataData(data));
        form.append('login', login);
        form.append('tipo_pesso', chave);
        form.append('codi_pesso', codigo);
        form.append('nome_conta', contato);
        form.append('desc_probl', descricao);
        form.append('codi_filia', 1);
        form.append('nome_progr', tipoProblema.split(' - ')[1]);
        form.append('chav_chama', tipoProblema.split(' - ')[0]);
        form.append('tipo_supor', tipoSuporte)

        if(!imagens){
            form.append('file', null)
        }else{
            for(let i = 0; i < imagens.length; i++){
                console.log(`Arquivo ${i + 1}:`, imagens[i].name, imagens[i]);
                form.append(`file${i+1}`, imagens[i])
            }

            form.forEach((value, key) => {
                console.log(key, value);
            });
            
    
            if(imagens.length > 0 && progressBar.pc !== 100){
                setUploadMessage('Anexando imagens, aguarde...')
            }
            
            setProgressBar(prevState => {
                return {...prevState, started: true}
            })

            api.post('/cadastro/chamado2', form,  { 
                onUploadProgress: (progressEvent) => { setProgressBar(prevState => {
                    return {...prevState, pc: progressEvent.progress * 100}
                })},
                headers: { 
                'Content-Type': 'multipart/form-data'
            }})
            .then((res)=> {
                if(res.status === 200) {
                    setResetImages(true);
                    toast.success("Chamado adicionado!")
                    setProgressBar({started: false, pc: 0})
                    setImagens([])
                    setImagesDetails([])
                }
            })
           .catch(err => {
                console.log(err)
           })
           
           setCodigo('')
           setContato('')
           setTipoProblema('')
           setDescricao('')
           setImagens([])
           setResetImages(true);
           setTipoSuporte('')

           return;
        }

        api.post('/cadastro/chamado2', form,  { 
            onUploadProgress: (progressEvent) => { setProgressBar(prevState => {
                return {...prevState, pc: progressEvent.progress * 100}
            })},
            headers: { 
            'Content-Type': 'multipart/form-data'
        }})
        .then((res)=> {
            if(res.status === 200) {
                toast.success("Chamado adicionado!")
                setProgressBar({started: false, pc: 0})
                setImagens([])
                setImagesDetails([])
                setResetImages(true);
            }
        })
       .catch(err => {
            console.log(err)
       })

        setCodigo('')
        setContato('')
        setTipoProblema('')
        setDescricao('')
        setTipoSuporte('')
        setImagens([])
        setImagesDetails([])
        setResetImages(true);

        
    }

    const preencheAutomatico = (chave, codigo, tipo_supor) => {
        setChave(chave)
        setCodigo(codigo)
        setTipoSuporte(tipo_supor)
    }


    function removeImage(e, index) {
        e.preventDefault();
      
        const newFileList = [...imagens];
        newFileList.splice(index, 1); 
        setImagens(newFileList); 
      
        const newImageDetails = [...imageDetails];
        newImageDetails.splice(index, 1);
        setImagesDetails(newImageDetails); 
    }

    const handleImagesChange = (newImages) => {
        setImagens(newImages); 
    };

    return (
        <BaseLayout>
           <main className="customer-content">

              <div className='titulo-clientes'>
                <div style={{display: 'flex', justifyItems: 'center', alignItems: 'center', gap: '10px'}}>
                 <h1> Consulta/Registra chamado de cliente</h1>
                 <span>
                    <p style={{fontSize: '18px', color: "#0d6efd"}}>
                        {verificaEquipe(localStorage.getItem("suporte_usuario")) 
                            ? ( <strong>Rodinfo</strong> ) 
                            : ( <strong>Parceria</strong> )
                        }
                    </p>
                 </span>
                </div>
              </div>                    

                <section className='actions-container'>
                    <div className='search'>
                        
                        <div>
                           <div style={{width: '100%', display: 'flex', gap: '10px'}}>
                           <span><strong>Digite o cliente desejado</strong></span> 
                            {clientes && ( <span style={{marginLeft: "1rem"}}>Total encontrado: <strong>{clientes.length}</strong></span> )}
                           <p>Usuário: <strong>{localStorage.getItem("suporte_usuario")}</strong></p>
                           </div>
                          <div style={{display: 'flex', width: '100%', justifyItems: 'center', gap: '8px'}}>
                          <input ref={buscaInputRef} onKeyPress={handleKeyPress} className='input' placeholder='ex: MM3' value={busca} onChange={(e)=> setBusca(e.target.value)}/>
                          <button className='botao-ok' onClick={handlePesquisaCliente}><icones.FiSearch size={24}/></button>
                          </div>
                        </div>
                        
                    </div>   
                    <Show>
                        <Show.When isTrue={loading}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <LoadingSpinner />
                            </div>
                        </Show.When>
                    </Show>
                    <Show>
                        <Show.When isTrue={error.error === true}>
                            <div className='erro-container'>
                                <span>{error.message}</span>
                            </div>
                        </Show.When>
                    </Show>  
                </section>                            
                
                {clientes.map((cliente, index) => (
                    <section key={cliente.codi_pesso} className={showOptions ? "expanded-customer-info-container" : "customer-info-container"}>
                        <div style={{width: "100%"}}>
                            <div className='customer-title'>
                                 <span onClick={() => preencheAutomatico("C", cliente.codi_pesso, cliente.tipo_supor)} className='client-name'>C - {cliente.codi_pesso}{" "}{cliente.nome_pesso}</span>
                                 <div className='retract-customer' onClick={() => toggleExpanded(index)}>
                                    <icones.MdOutlineKeyboardArrowDown size={28} />
                                </div>
                            </div>

                            <div className='customer-info'>
                              {expandedBlocks[index] && (
                                <div className='infos'>

                                   <div className='outras-infos'>
                                        <Input titulo="Versão do Sistema"  valor={cliente.nome_siste} isDisabled={true} />
                                        <Input titulo="Senha do Banco de Dados"  valor={cliente.senh_bd} isDisabled={true}/>
                                        <Input titulo="Usuário Backup Nuvem"  valor={cliente.emai_backu} isDisabled={true}/>     
                                        <Input titulo="Senha Backup Nuvem" valor={cliente.senh_backu} isDisabled={true}/>       

                                        <textarea value={cliente.obse_geral} disabled className='text-area-cliente'/>                       
                                    </div>

                                    <div className='acessos'>
                                        <div className='itens-acessos'>
                                          <Input titulo="Usuário do Windows" valor={cliente.usua_windo} isDisabled={enableEdit}/>
                                          <Input titulo="Senha do Windows"  valor={cliente.senh_windo} isDisabled={enableEdit}/>
                                        </div>
                                        <Input titulo="Acesso Remoto / VPN" valor={cliente.ende_remot} isDisabled={enableEdit}/> 
                                        <div className='itens-acessos'>
                                          <Input titulo="ID Team Viewer" valor={cliente.id___teamv} isDisabled={enableEdit}/>
                                          <Input titulo="Senha Team Viewer"  valor={cliente.senh_teamv} isDisabled={enableEdit}/>
                                        </div>
                                        <Input titulo="ID Anydesk" valor={cliente.id___anyde} isDisabled={enableEdit}/>
                                        <Input titulo="Senha Anydesk" valor={cliente.senh_anyde} isDisabled={enableEdit}/>
                                    </div> 
                               </div>                                   
                            )}
                         </div>
                        </div>
                    </section>
                ))}

                <div className='register-container'>
                    <form className='registra-form'>
                        <span>Registrar chamado</span>
                        <div>
                            <input className='input' type='date' value={data} disabled/>
                        </div>
                        <div className='registra-form-items'>
                            <input className='input' type='text' placeholder='Chave' value={chave} onChange={(e) => setChave(e.target.value)} disabled/>
                            <input className='input' type='text' placeholder='Código do cliente' value={codigo} onChange={(e) => setCodigo(e.target.value)}/>
                            <Programa onChange={handleProgramaChange}/>
                        </div>
                            <input className='input' type='text' placeholder='Nome do contato' value={contato} onChange={(e) => setContato(e.target.value)}/>
                            <input className='input' type='text' placeholder='Descrição do problema' value={descricao} onChange={(e) => setDescricao(e.target.value)}/>
                            <input className='input' type="text" placeholder='login' value={localStorage.getItem("suporte_usuario")} disabled/>

                            <div className="mediaContainer">
                                <UploadComponent text="Anexar imagens"  type='CH' onImagesChange={handleImagesChange} resetTrigger={resetImages}/>

                                {progressBar.started === true && 
                                    (
                                        <div>
                                            {progressBar.pc === 100 ? (
                                            <span>
                                                <p>Imagens anexadas com sucesso </p>
                                            </span>
                                            ): (
                                            <span>
                                                <p>Anexando imagens...</p>
                                            </span>
                                            )
                                            }
                                            <progress max="100" value={progressBar.pc} className="progress-bar-color"></progress>
                                        </div>
                                    )
                                }

                                {imageDetails.length > 0 && (
                                <section style={{display: 'flex', flexDirection: 'column', justifyItems: 'center'}}>
                                <span>
                                    <p>Total de imagens: {imagens.length}</p>
                                </span>
                                    <article className='file-items-container'>
                                        {Array.from(imageDetails).map((item, index) => (
                                            <div>
                                                <span key={item.index} className='file-item'>
                                                    <p className='file-item-name'>
                                                        <icones.FaRegFileImage size={20} color="#0d6efd"/>
                                                        {item.name}
                                                    </p>
                                                    <button className='remove-file-btn' onClick={(e) => removeImage(e, index)}>
                                                        <icones.AiOutlineClose size={24} />
                                                    </button> 
                                                </span>
                                            </div>
                                        ))}
                                    </article>
                                </section>
                                )}
                                </div>

                        <button onClick={handleRegister} className='botao-registra'>
                            Registrar
                        </button>
                    </form>
                </div>
                {verificaEquipe() ? (
                    <div>
                      <span>Data build: 28.10.2024</span>
                    </div>
                ) : (<></>)}
           </main>
        </BaseLayout>
    )
}