import { useEffect, useState } from "react";

import UploadComponent from '../UploadComponent'

import { Dock } from "react-dock";
import api from "../../api/api";

import styles from './styles.module.css'
import toast from "react-hot-toast";
import FlexButton from "../ui/FlexButton";


export default function EditModalTarefas({show, nome_cliente, login, nume_lanca, desc_lanca, codi_pesso, handleClose}){

    const [clientes, setClientes] = useState([])
   
    const [codigoCliente, setCodigoCliente] = useState('')
    const [clienteSelecionado, setClienteSelecionado] = useState(null)
    
    const [idTarefa, setIdTarefa] = useState(null)
    const [desc, setDesc] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredClients, setFilteredClients] = useState(clientes);

    const [error, setError] = useState({
      error: false,
      message: ''
    })

    const [progressBar, setProgressBar] = useState({ started: false, pc: 0 });
 
    const [imagens, setImagens] = useState([]);
    const [resetImages, setResetImages] = useState(false);

    useEffect(()=>{
      async function carregaClientes(){
        const response = await api.get(`/consulta/cliente/*}|${localStorage.getItem("suporte_usuario")}`)
        setClientes(response.data.dados)
      }
      carregaClientes();
    },[])

    useEffect(()=>{
      setIdTarefa(nume_lanca)
      setSearchTerm(nome_cliente)
      setCodigoCliente(codi_pesso)
      setDesc(desc_lanca)
    },[nume_lanca])
 

    function resetFields() {
      setDesc('');
      setError({ error: false, message: '' });
      setImagens([]);
      setResetImages(true);
    }

    async function SalvarEdicao() {

      if (!desc || !codigoCliente || !searchTerm) {
        alert('Todas as informações são necessárias');
        return;
      }
    
      const form = new FormData();
      
      form.append('login', login);
      form.append('nume_lanca', nume_lanca);
      form.append('desc_lanca', desc);
      form.append('codi_pesso', codigoCliente);
      form.append('nome_conta', searchTerm)
              

      for (let i = 0; i < imagens.length; i++) {
        console.log(`Arquivo ${i + 1}:`, imagens[i].name, imagens[i]);
        form.append(`file${i + 1}`, imagens[i]);
      }

    
      form.forEach((value, key) => {
        console.log(key, value);
      });
    
      setProgressBar((prevState) => {
        return { ...prevState, started: true };
      });
    
      try {
        const response = await api.put('/modifica/tarefa', form, {
          onUploadProgress: (progressEvent) => {
            setProgressBar((prevState) => {
              return { ...prevState, pc: progressEvent.progress * 100 };
            });
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
        if (response.status === 200) {
          toast.success('Tarefa modificada!');
          setProgressBar({ started: false, pc: 0 });
          handleCloseModal();

        }
      } catch (error) {
          console.error('Erro ao modificar tarefa:', error);
          toast.error('Erro ao modificar tarefa.');
      }
    }

    function handleCloseModal() {
      resetFields();
      handleClose();
      setResetImages(true);
      setTimeout(() => setResetImages(false), 0);
    }

    function handleSearch(event){
      const term = event.target.value;
      setSearchTerm(term);
    
      const filtered = clientes.filter(cliente =>
        cliente.nome_fanta.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredClients(filtered);

      if(term.length === 0){
        setFilteredClients([])
      }

    };

    function handleSelect(cliente){
      setSearchTerm(cliente.nome_pesso);
      setClienteSelecionado(cliente.nome_pesso)
      setCodigoCliente(cliente.codi_pesso);
      setFilteredClients([]); 
    };

    function handleImagesChange(newImages){
      setImagens(newImages); 
    };

    async function atualizaStatusTarefa(tipo_status) {
      let status;
    
      switch (tipo_status) {
        case 'pendente':
          status = 0;
          break;
    
        case 'andamento':
          status = 1;
          break;
    
        case 'testando':
          status = 2;
          break;
    
        default:
          toast.error("Status inválido.");
          return;
      }
    
      const info = {
        nume_lanca: idTarefa,
        login: localStorage.getItem("suporte_usuario"),
        desc_statu: status
      };
    
      await api.post(`/atualiza/tarefas`, info);
      toast.success(`Tarefa movida para: ${tipo_status.toUpperCase()}.`);

    }


    return (
        <Dock
          isVisible={show}
          position="right"
          fluid={false}
          size={420}
          onVisibleChange={handleCloseModal}
        >   
          <div className={styles.modalContainer}>
            <div>

            <div className={styles.titulo}>
              <h3>Editando Tarefa</h3>
              <h3>ID: {idTarefa}</h3>  
            </div>

            <div style={{display: "flex", gap: '10px', padding: '10px'}}>
              <input type="text"
                  className={styles.input}
                  value={codigoCliente}
                  onChange={handleSearch}
                  disabled
                  placeholder="Código"
                />
              <input
                type="text"
                className={styles.input}
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Buscar cliente..."
                maxLength={120}
              />
                <ul className={styles.dropdown}>
                  {filteredClients.map((cliente, index) => (
                    <li
                      key={index}
                      className={styles.dropdownItem}
                      onClick={() => handleSelect(cliente)}
                      >
                    {cliente.codi_pesso} - {cliente.nome_fanta}
                    </li>
                  ))}
                </ul>
              </div>

          
            <div className={styles.singleItem}>
              <label>
                Descrição:
                <textarea className={styles.descricaoTarefa} maxLength={500} value={desc} onChange={(e) => setDesc(e.target.value)}/>
              </label>
            </div>


            <div className={styles.singleItem}>
              <UploadComponent type='TA' onImagesChange={handleImagesChange} resetTrigger={resetImages}/>
            </div>            
            </div>          

        
            <div className={styles.actions}>

              <div className={styles.informaStatus}>
                <FlexButton text='Não analisado'  color='#dc3545' onClick={() => atualizaStatusTarefa('pendente')} />
                <FlexButton text='Em andamento'   color='#0d6efd' onClick={() => atualizaStatusTarefa('andamento')} />
                <FlexButton text='Testando'       color='#34d399' onClick={() => atualizaStatusTarefa('testando')} />
              </div>


              <div>
                <button className={styles.saveButton} onClick={SalvarEdicao}>Salvar</button>
                <button className={styles.closeButton} onClick={handleCloseModal}>Fechar</button>
              </div>

            </div>
          </div>
        </Dock>
    )
}

