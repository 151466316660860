import { useEffect, useState } from 'react'

import axios from 'axios'

import BaseLayout from '../suporte/restrito/layout/BaseLayout'

import icones from '../../assets/icons/icones';
import styles from './styles.module.css'


export default function RegistrarResolucao(){

  const [avatarUrl, setAvatarUrl] = useState('');
  const [imageAvatar, setImageAvatar] = useState(null);  

  const [stream, setStream] = useState(null);
  

    function handleFile(e){
      if(!e.target.files){
        return;
      }

      const image = e.target.files[0];

      if(!image){
        return;
      }

      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageAvatar(image);
        setAvatarUrl(URL.createObjectURL(e.target.files[0]))

      }
    }

    function abrirCamera(e) {
      e.preventDefault();
      let video = document.querySelector('video');
    
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const videoDevices = devices.filter(device => device.kind === 'videoinput');
          const backCamera = videoDevices.find(device => device.label.toLowerCase().includes('back'));
          
          return navigator.mediaDevices.getUserMedia({
            video: { deviceId: backCamera ? { exact: backCamera.deviceId } : undefined }
          });
        })
        .then(stream => {
          video.srcObject = stream;
          video.play();
        })
        .catch(error => {
          console.log(error);
        });
    }

    function tirarFoto(e) {
        e.preventDefault();

        let video = document.querySelector('video');
        let canvas = document.querySelector('canvas');
        let context = canvas.getContext('2d');
    
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
    
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
    
        const imageDataUrl = canvas.toDataURL('image/png');
    
        setAvatarUrl(imageDataUrl);
    }

    function excluirMedias(e){
      e.preventDefault();

      setImageAvatar('');
      setAvatarUrl('');
      setStream('')
    }


    async function enviarFormulario(e){
      e.preventDefault();

      try{
        const data = new FormData();

        data.append('file', imageAvatar)

        const response = await axios.post('https://syshorti.com.br:2083/cadastro/resolucao', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        console.log(response)
      }catch(error){
        console.log(error)
      }

    }


    return (
      <BaseLayout>  
        <main className={styles.mainContent}>
          <div className={styles.title}>
            <h2>Adicionar resolução</h2>
          </div>
            <section>
              <div className={styles.content}>
                <form>
                  <input className='input' placeholder='Atribuir a um chamado'/>
                  <input className='input' placeholder='Adicionar Título'/>

                  <div className={styles.mediaContainer}>
                    <label className={styles.labelAvatar}>
                       <span>
                         <icones.LuUpload size={25} color="#0d6efd"/>
                       </span>
                       <input type="file" accept="image/*" onChange={handleFile}/>

                       {avatarUrl && (
                         <img 
                           className={styles.preview}
                           src={avatarUrl}
                           alt="Foto do problema"
                           width={250}
                           height={250}
                         />
                       )}
                     </label>

                          
                      <video className={styles.video}>
                        <canvas className={styles.canvas}></canvas>
                      </video>

                    </div>

                    <div className={styles.actions}>
                      <button className={styles.btnEnviar}   onClick={enviarFormulario}>Enviar</button>
                      <button className={styles.btnCamera}   onClick={abrirCamera}><icones.MdOutlineCameraAlt color='#FFF' size={24}/> Câmera</button>
                      <button className={styles.btnCapturar} onClick={tirarFoto}>Capturar</button>
                      <button className={styles.btnExcluir}  onClick={excluirMedias}><icones.FiTrash2 color="#FFF" size={24}/> Excluir</button>
                    </div>

                </form>
              </div>
            </section>
        </main>
      </BaseLayout>
    )
}