import styles from './styles.module.css'

export function Input({ titulo, valor, placeholder, isDisabled, onChange, tipo }) {
    return (
        <div className={styles.input_container}>
            <span>
                <p className={styles.input_title}>{titulo}</p>
            </span>
            <input
                type={tipo}
                className={styles.input}
                placeholder={placeholder}
                value={valor}
                disabled={isDisabled}
                onChange={e => onChange(e.target.value)}
            />
        </div>
    );
}