import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'



import  sysHortiLogo  from '../../assets/sysHortiLogo.png'

import api from '../../api/api'
import { IoRefresh } from "react-icons/io5";


import './styles.css'
import toast from 'react-hot-toast';

const Suporte = () => {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  const [erro, setErro] = useState(false)
  const [erroServidor, setErroServidor] = useState(false)

  const [rememberUser, setRememberUser] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  const handleLogin = (e) => { 
    e.preventDefault();
    
    let params = JSON.stringify({
      login: user,
      senha: password
    });
    
    api.post('/autorizausuario', params, {
      headers: {
        'Content-Type': 'application/json',
      }, 
    })
    .then((res)=> {
      if(res.status === 200){
        localStorage.setItem("suporte_usuario", user);
        navigate('/restrito/clientes');
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        setErro(true);
        return;
      }
    });
  };
  
  const handleRememberUser = () => {
    setIsChecked(!isChecked);
    setRememberUser(!isChecked);
  };
  
  const bringUserFromLocalStorage = () => {
    let rememberedUser = localStorage.getItem("suporte_usuario");
    let isChecked = localStorage.getItem("isChecked");
  
    setUser(rememberedUser || '');
    setIsChecked(isChecked === "true");
    setRememberUser(isChecked === "true");
  };
  
  useEffect(() => {
    bringUserFromLocalStorage();
  }, []);
  
  
  function clearTrashCache(){
    localStorage.clear();
    sessionStorage.clear();
    toast.success('Feito!')
  }


  useEffect(() => {
    if (isChecked) {
      localStorage.setItem("isChecked", "true");
      localStorage.setItem("suporte_usuario", user);
    } else {
      localStorage.removeItem("isChecked");
      localStorage.removeItem("suporte_usuario");
    }
  }, [isChecked, user]);


  

  return (
      <>
            <main className='login-page'>
      <section className='login-container'>
         <a href="https://www.rodinfo.com.br/">
          <img src={sysHortiLogo} className='login-img'/>
         </a>
         <span>Login - Suporte área restrita</span>
          <form className='form-container'>
            <input 
              placeholder='Usuário'
              className='input'
              value={user}
              maxLength={14}
              onChange={(e) => setUser(e.target.value.toLocaleLowerCase().trim())}
            />

            <input
              placeholder='Senha'
              className='input'
              type='password'
              value={password}
              maxLength={20}
              onChange={(e) => setPassword(e.target.value)}
            />

          <div style={{width: "100%", display: "flex", gap: "1rem"}}>
            <input type="checkbox" 
                   value={isChecked} 
                   onChange={handleRememberUser}
                   checked={isChecked}
                   className="login-checkbox" />
            <label>Lembrar usuário</label>
          </div>
            {erro && (
              <div className='erro-container'>
                <span>Usuário não encontrado</span>
              </div>
              )}
              {erroServidor && (
              <div className='erro-container'>
                <span>Não foi possível se conectar com o servidor</span>
              </div>
              )}
              <div className='buttons'>
                <button className='login-button' onClick={handleLogin}>Acessar</button>
              </div>
          </form>
      </section>
    </main>
    
      <div style={{width: "100%", display:"flex", justifyContent: "end", padding: '10px', marginTop: '-50px'}}>
         <button onClick={clearTrashCache}>
            <IoRefresh size={24}/>
         </button>
      </div>
      </>
  )
}

export default Suporte
