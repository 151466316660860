import { useState } from "react";
import { useFetchProgramas } from "../../../../../hooks/useFetchProgramas"

export default function Programa(props){
    const [selected, setSelected] = useState('')
    const fetchProgramas = useFetchProgramas()
    
    let listaProgramas = fetchProgramas.programa ? fetchProgramas.programa.map((x) => x) : [];

    const handleSelectionChange = (e) => {
        setSelected(e.target.value);
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    };

    return (
        <select className='input' value={selected} onChange={handleSelectionChange}>
          <option value="" placeholder="">Módulo</option>
          {listaProgramas.map((x, index)=> (
            <option key={index} value={`${x.nome_progr} - ${x.desc_progr}`}>{x.desc_progr} - {x.nome_progr}</option>
           ))}
            <option value="Outros - AlumiPortas">AlumiPortas</option>
            <option value="Outros - Indústria">Indústria</option>
            <option value="Outros - Biovida">Biovida</option>  
            <option value="Outros - Aplicativo">Aplicativo</option>
            <option value="Outros - Permissões">Permissões</option>
            <option value="Outros - Dúvidas">Dúvidas</option>
            <option value="Outros - Certificado">Instalação certificado</option>
            <option value="Outros - Mapeamento e conexão">Mapeamento e conexão</option>
            <option value="Outros - Instalação de impressoras">Impressoras</option>
            <option value="Outros - Relatórios">Relatórios</option>
            <option value="Outros - Recibos">Recibos</option>
            <option value="Outros - Outros">Outros</option>
            <option value="Outros - Implantação">Implantação</option>
            <option value="Outros - Terminal">Terminal</option>
       </select>
    )
}